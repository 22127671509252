import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InstructionService {
  url=environment.url
  constructor(private http:HttpClient, private router:Router) { }

  addInstruction(value){
    return this.http.post(this.url+'addInstruction',value)
  }

  editInstruction(id){
    // console.log(id)
    this.router.navigate(['level/edit/'+id])
  }

  editInstructionData(value){
    return this.http.post(this.url+'editInspiration',value)
  }

  getInstructionData(value){
    console.log('params',value)
    return this.http.get(this.url+'getInstructionData/'+value)
  }

  update(value){
    return this.http.post(this.url+'updateInstruction',value)
  }

  delete(id){
    return this.http.post(this.url+'deleteInstruction',{data:id})
  }

  displayLevel(value){
    this.router.navigate(['video/'+value])
  }

  deleteProductLevel(value){
    console.log(value)
    return this.http.post(this.url+'deleteProductLevel',value)
  }
}
